import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import axios from "helpers/axiosInstance";
import { composeApiURL } from "helpers/utils";

import { State } from "redux/store";
import {
  SET_DATASETS,
  IS_LOADING,
  SET_SAMPLE_DATA,
  IS_CONTENT_LOADING,
  SET_DATASET_ID,
  SET_SELECTED_SAMPLE_DATASET,
  IS_ERROR,
  ERROR_TEXT,
  SET_PROFILE_URL,
} from "./constants";

export const setContentLoader = (data: any) => {
  return {
    type: IS_CONTENT_LOADING,
    data,
  };
};

export const setLoader = (data: any) => {
  return {
    type: IS_LOADING,
    data,
  };
};

export const setSampleData = (data: any) => {
  return {
    type: SET_SAMPLE_DATA,
    data,
  };
};

export const setSelectedSampleDataset = (data: any) => {
  return {
    type: SET_SELECTED_SAMPLE_DATASET,
    data,
  };
};

export const setDatasetId = (data: any) => {
  return {
    type: SET_DATASET_ID,
    data,
  };
};

export const setDatasets = (data: any) => {
  return {
    type: SET_DATASETS,
    data,
  };
};

export const setIsError = (data: any) => {
  return {
    type: IS_ERROR,
    data,
  };
};

export const setErrorText = (data: any) => {
  return {
    type: ERROR_TEXT,
    data,
  };
};
export const setProfileUrl = (data: any) => {
  return {
    type: SET_PROFILE_URL,
    data,
  };
};

export const fetchDatasets = (): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    dispatch(setDatasets([]));
    dispatch(setContentLoader(true));
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        "/datasets"
      );
      const response = await axios.get(url);
      dispatch(setDatasets(response.data));
      dispatch(setContentLoader(false));
    } catch (error) {
      dispatch(setContentLoader(false));
      console.log(error);
    }
  };
};

export const fetchSampleData = (): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        "/datasets/sample_data"
      );
      const response = await axios.get(url);
      let newArray = response.data.map((obj: any) => ({
        id: obj.id,
        display_name: obj.display_name,
        description: obj.description,
      }));
      dispatch(setSampleData(newArray));
      dispatch(setSelectedSampleDataset(newArray[0]));
    } catch (error) {
      console.log(error);
    }
  };
};

export const checkBucketList = (
  bucketName?: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/bucket/${bucketName}`
      );
      const response = await axios.get(url);
      if (response.status === 200) {
        dispatch(setErrorText(""));
      }
    } catch (error: any) {
      dispatch(setErrorText(error.response.data.detail));
    }
  };
};

export const createDataset = (
  datasetName: any,
  s3Bucket?: any,
  selectedSampleId?: any,
  file?: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoader(true));
    dispatch(setDatasetId(null));
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        "/dataset"
      );

      var bodyFormData = new FormData();
      datasetName && bodyFormData.append("dataset_name", datasetName);
      s3Bucket && bodyFormData.append("bucket_name", s3Bucket);
      selectedSampleId &&
        bodyFormData.append("vl_dataset_id", selectedSampleId);
      file && bodyFormData.append("uploaded_filename", file.name);

      const response = await axios.post(url, bodyFormData);

      dispatch(setDatasetId(response.data.id));
      if (file) {
        dispatch(uploadDataset(response.data.id, file) as any);
      }
      dispatch(setLoader(false));
    } catch (error: any) {
      dispatch(setLoader(false));
      dispatch(setIsError(true));
    }
  };
};

export const uploadDataset = (
  datasetId: any,
  file: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoader(true));
    try {
      const url =
        process.env.REACT_APP_API_ENDPOINT +
        "/api/v1/dataset/" +
        datasetId +
        "/upload";

      var bodyFormData = new FormData();
      bodyFormData.append("file", file);
      await axios.post(url, bodyFormData);
      dispatch(setLoader(false));
    } catch (error: any) {
      dispatch(setLoader(false));
    }
  };
};

import VLButton from "views/uikit/VLButton";

import styles from "./style.module.scss";

function Header(props: any) {
  return (
    <div className={styles.headerWrapper}>
      <div className={styles.headerContainer}>
        <div className={styles.headerLeft}>
          <div className={styles.headerText}>
            <span> All Datasets </span>
          </div>
        </div>
        <div className={styles.headerRight}>
          <VLButton
            text={"+ New Dataset"}
            onClick={() => props.setShowModal(true)}
          />
        </div>
      </div>
      <div className={styles.horizontalRules}>
        <hr className={styles.horizontalRule1} />
        <hr className={styles.horizontalRule2} />
      </div>
    </div>
  );
}

export default Header;

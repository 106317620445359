import ContentLoader from "react-content-loader";

const ContentLoad = () => {
  return (
    <ContentLoader
      speed={0.5}
      width={"100%"}
      height={"100%"}
      backgroundColor="#c5c1c1"
      foregroundColor="#6c757d"
    >
      <rect width="100%" rx="30px" height="100%" />
    </ContentLoader>
  );
};

export default ContentLoad;

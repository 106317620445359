import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import Image from "react-bootstrap/Image";

import { setProfileUrl } from "redux/Datasets/actions";
import { getProfileUrl } from "redux/Datasets/selectors";

import { fetchGithubAvatar } from "helpers/utils";

import dummyImage from "assets/img/avatar.svg";

import styles from "./style.module.scss";

const VLAvatar = (props: any) => {
  const { size, profileURL } = props;

  const dispatch = useDispatch();

  const handleAvatar = async (token: string, origin: string) => {
    if (origin === "google") {
      let info = { picture: "" };
      info = jwt_decode(token)!;

      dispatch(setProfileUrl(info.picture));
    } else if (origin === "github") {
      let info = await fetchGithubAvatar(token);
      dispatch(setProfileUrl(info));
    }
  };

  useEffect(() => {
    let token = Cookies.get("user_token");
    let origin = Cookies.get("user_origin");
    handleAvatar(token!, origin!);
    // eslint-disable-next-line
  }, []);

  let imageSize = styles.small;

  switch (size) {
    case "medium":
      imageSize = styles.medium;
      break;

    case "large":
      imageSize = styles.large;
      break;
  }

  return (
    <div>
      <Image
        className={imageSize}
        src={profileURL ? profileURL : dummyImage}
        roundedCircle
      />
    </div>
  );
};

function mapStatesToProps(state: any) {
  return {
    profileURL: getProfileUrl(state),
  };
}
export default connect(mapStatesToProps)(VLAvatar);

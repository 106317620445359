const ProcessItem = (props: any) => {
  const { icon, title, body }: { icon: string; title: string; body: string } =
    props;

  return (
    <div className="work-process-item">
      <div className="work-process-icon">
        <img src={icon} alt="upload" />
      </div>
      <div className="work-process-text">
        <div className="work-process-head">
          <span>{title}</span>
        </div>
        <div className="work-process-body">
          <span>{body}</span>
        </div>
      </div>
    </div>
  );
};

export default ProcessItem;

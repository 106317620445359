import { useCallback, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import { userLogout } from "redux/authReducer";

import { MdOutlineLogin } from "react-icons/md";

import styles from "./style.module.scss";

function ProfileModal(props: any) {
  const { showModal, setShowModal } = props;

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const handleLogout = () => {
    userLogout();
  };
  const listener = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeModal();
        document.removeEventListener("keydown", listener);
      }
    },
    [closeModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [closeModal, listener]);

  return (
    <Modal
      animation={false}
      show={showModal}
      onHide={closeModal}
      className={styles.modalMainContainer}
    >
      <Modal.Body className={styles.modalInnerContainer}>
        <ul className={styles.optionsListContainer}>
          <li className={styles.listStyle} onClick={handleLogout}>
            <MdOutlineLogin className={styles.listIcon} size="22px" />
            <span className={styles.logoutOption}>Log Out</span>
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
}
export default ProfileModal;

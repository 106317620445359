import { GoCheck as GreenTick } from "react-icons/go";
import { validateDatasetName } from "helpers/validations";

import SourceItem from "views/components/SourceItem";

import styles from "./style.module.scss";

function SelectSourceType(props: any) {
  const { errorFlag, setErrorFlag } = props;

  const handleInputValidation = (value: any) => {
    let result = validateDatasetName(value);
    result ? setErrorFlag(result) : setErrorFlag("");
  };
  return (
    <div
      className={props.show ? styles.selectSourceType : styles.hideSourceType}
    >
      <div className={styles.basicDetailsTop}>
        <span>Dataset title</span>
      </div>
      <div className={styles.addInputDataset}>
        <input
          type="text"
          placeholder="Add dataset title e.g my first dataset"
          className={errorFlag ? styles.errorFlagInput : styles.addInput}
          onChange={(e) => {
            props.handleChange(e.target.value);
            handleInputValidation(e.target.value);
          }}
          value={props.datasetName}
        />
        <div
          className={
            props.datasetName === "" || errorFlag
              ? styles.disabledGreenTick
              : undefined
          }
        >
          <GreenTick size="1em" color="#43E625" className={styles.greenTick} />
        </div>
        <span className={styles.errorFlag}>{errorFlag ? errorFlag : ""}</span>
      </div>
      <div
        className={
          props.datasetName === "" ? styles.disabledSourceContainer : undefined
        }
      >
        <div className={styles.basicDetailsBelow}>
          <span>Select Source</span>
        </div>
        <div className={styles.sourceInnerContainer}>
          <SourceItem
            name="S3 Connect"
            description=""
            selected={props.selectedSource === 2}
            onClick={() => {
              props.onSelectSource(2);
              props.setSelectedSourceName("S3 Connect");
            }}
          />
          <SourceItem
            name="File Upload"
            description="Upload .tar or .zip"
            selected={props.selectedSource === 1}
            onClick={() => {
              props.onSelectSource(1);
              props.setSelectedSourceName("File Upload");
            }}
          />
          <SourceItem
            name="Sample Data"
            description="Try Visual Layer customized data"
            selected={props.selectedSource === 3}
            onClick={() => {
              props.onSelectSource(3);
              props.setSelectedSourceName("Sample Data");
            }}
          />
        </div>
      </div>
    </div>
  );
}
export default SelectSourceType;

import Cookies from "js-cookie";

import { User } from "types";

export function selectUser(state?: any): User | null {
  let token = Cookies.get("user_token");
  if (typeof token === "undefined") {
    return null;
  }

  let origin = Cookies.get("user_origin");
  if (typeof origin === "undefined") {
    return null;
  }

  return { token: token, origin: origin };
}

export function userLogout() {
  try {
    Cookies.remove("user_token");
    Cookies.remove("user_origin");
    window.location.pathname = "/login";
  } catch (e) {
    console.log(e);
  }
}

import { useState } from "react";
import { useDispatch } from "react-redux";

import { checkBucketList } from "redux/Datasets/actions";
import { validateBucketName } from "helpers/validations";

import DragDrop from "views/components/DragDrop";

import { MdAutorenew as AutoRenewImg } from "react-icons/md";
import { GoCheck as GreenTick, GoX as CrossImg } from "react-icons/go";

import S3UploadImage from "assets/pngImages/S3-upload.png";
import LocalImg from "assets/pngImages/Local_upload.png";

import styles from "./style.module.scss";

function UploadDataset(props: any) {
  const [localLoading, setLocalLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { bucketNameError, setBucketNameError, errorText, setErrorText } =
    props;

  const dispatch = useDispatch();
  //----------------- Debug statements---------------//

  //-------------------------------------------------//
  const handleInputValidation = (value: any) => {
    let result = validateBucketName(value);

    if (result) {
      setBucketNameError("");
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
      setBucketNameError(
        "Only lowercase letters, numbers, dots (.) and hyphens (-) allowed."
      );
    }
  };

  const handleReset = (value: any) => {
    if (value === "") {
      setErrorText(null);
      setBucketNameError("");
      setIsButtonDisabled(true);
    }
  };
  const handleTest = async () => {
    setLocalLoading(true);
    //updates errortext in store to be "" or ~"error message"
    await dispatch(checkBucketList(props.fileUrl) as any);

    setLocalLoading(false);
  };

  const handleImageDisplay = () => {
    if (localLoading) {
      return <AutoRenewImg size="1em" color="#C0BFBD" />;
    }
    if (!localLoading) {
      if (errorText === "") {
        return <GreenTick size="1em" color="#43E625" />;
      } else if (errorText) {
        return <CrossImg size="1em" color="#FF5454" />;
      } else {
        return null;
      }
    }
  };

  return (
    <>
      <div className={props.show ? "d-block" : "d-none"}>
        <div className={styles.list}>
          <div className={styles.sourceName}>
            {props.selectedSourceName}&nbsp;
          </div>
          <div className={props.isLocalUpload ? styles.localImage : "d-none"}>
            <img src={LocalImg} alt="" />
          </div>
        </div>
        <div className={props.isLocalUpload ? "d-none" : styles.iconImage}>
          <img src={S3UploadImage} alt="" />
        </div>

        <div
          className={props.isLocalUpload ? "d-none" : styles.addInputDataset}
        >
          <input
            type="text"
            placeholder="Enter Public S3 Bucket Name"
            className={`${props.fileUrl ? styles.uploaded : styles.addInput} ${
              bucketNameError || errorText ? styles.addErrorInput : undefined
            }`}
            onChange={(e) => {
              props.setFileUrl(e.target.value);
              handleInputValidation(e.target.value); //handles button state too
              handleReset(e.target.value);
              dispatch(setErrorText(null));
            }}
            value={props.fileUrl}
          />
          <div
            className={styles.syncImg}
            onClick={() => {
              if (errorText) {
                props.setFileUrl("");
                dispatch(setErrorText(null));
                handleImageDisplay();
              }
            }}
          >
            {handleImageDisplay()}
          </div>

          {bucketNameError && (
            <span className={styles.bucketNameError}>{bucketNameError}</span>
          )}
          {errorText && props.fileUrl !== "" && (
            <span className={styles.isError}>{errorText}</span>
          )}
          <div className={styles.test}>
            <button
              onClick={() => {
                setIsButtonDisabled(true);
                handleTest();
              }}
              className={
                isButtonDisabled ? styles.disabledTestBtn : styles.testBtn
              }
            >
              Test
            </button>
          </div>
        </div>
        <DragDrop {...props} />
      </div>
    </>
  );
}

export default UploadDataset;

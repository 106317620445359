import { v4 as uuidv4 } from "uuid";
import { Helmet } from "react-helmet";

import ProcessItem from "./ProcessItem";
import LoginButton from "./LoginButton";

import { selectUser } from "redux/authReducer";

import vllogo from "assets/img/login-vllogo.svg";
import upload from "assets/img/upload.svg";
import fix from "assets/img/fix.svg";
import scan from "assets/img/scan.svg";
import githubLogo from "assets/img/github-logo.svg";
import googleLogo from "assets/img/google-logo.svg";

import "./style.scss";

function backendLoginUrl() {
  if (window.location.host.startsWith("localhost")) {
    return "http://localhost:9999";
  } else {
    return window.location.protocol + "//" + window.location.host;
  }
}

function Login() {
  let user = selectUser();
  if (user?.token) {
    window.location.pathname = "/datasets";
  }

  const serverUrl = backendLoginUrl();

  const generateGithubSSOUrl = () => {
    return (
      "https://github.com/login/oauth/authorize?scope=user&client_id=" +
      process.env.REACT_APP_GITHUB_CLIENT_ID +
      "&state=" +
      uuidv4()
    );
  };

  return (
    <div className="login-container">
      <Helmet>
        <script
          src="https://accounts.google.com/gsi/client"
          async
          defer
        ></script>
      </Helmet>
      <div className="left-section">
        <div className="logo-image">
          <img src={vllogo} alt="logo" height={"60px"}/>
        </div>
        <div className="login-body">
          <div className="login-text">
            <span>
              Automatically find and fix quality issues in your visual-data
            </span>
          </div>
          <div className="work-process">
            <ProcessItem
              icon={upload}
              title="1. Upload your dataset"
              body="We'll show you how to organize and upload your data to get started."
            />
            <ProcessItem
              icon={scan}
              title="2. Scan"
              body="Check visuals and metadata with as many filters as you like."
            />
            <ProcessItem
              icon={fix}
              title="3. Fix"
              body="Then when you're ready, download your metadata. That's it!"
            />
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="login-text1">
          <span>Get started for free</span>
        </div>
        <div className="login-with-section">
          <a href={generateGithubSSOUrl()}>
            <LoginButton
              className="bg-dark"
              icon={githubLogo}
              text="Log in with Github"
            />
          </a>
          <LoginButton
            className="google-login-button"
            icon={googleLogo}
            text="Log in with Google"
          />
          <div className="google-auth-login">
            <div
              id="g_id_onload"
              data-client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              data-context="signin"
              data-ux_mode="popup"
              data-login_uri={serverUrl + "/api/v1/google-sso"}
              data-nonce=""
              data-auto_prompt="false"
            ></div>
            <div
              className="g_id_signin"
              data-width={window.innerWidth > 700 ? 300 : 230}
            ></div>
          </div>
        </div>
        <div className="agree-terms">
          <input type="checkbox" id="agree-terms" />
          <label htmlFor="agree-terms">
            I Agree To The Terms And Conditions And The Privacy Policy
          </label>
        </div>
      </div>
    </div>
  );
}

export default Login;

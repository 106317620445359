import { kFormatter } from "helpers/utils";
import styles from "./styles.module.scss";

const StatsItem = (props: any) => {
  return (
    <div className={styles.high}>
      <div
        className={styles.highScore}
        style={{ backgroundColor: props.color }}
      >
        {props.severity}
      </div>
      <div className={styles.highCount}>{kFormatter(props.count)}</div>
    </div>
  );
};

export default StatsItem;

import { useState, useRef } from "react";

import { RxCross2 } from "react-icons/rx";

import { formatBytes } from "helpers/utils";
import { validateLocalUpload } from "helpers/validations";

import uploadIcon from "assets/img/upload-icon.svg";
import addFileIcon from "assets/img/add-file-icon.svg";
import fileImage from "assets/img/file-icon.svg";

import styles from "./style.module.scss";

function DragDrop(props: any) {
  const [isDropping, setIsDropping] = useState(false);
  const inputRef = useRef<any>(null);

  const { fileError, setFileError } = props;

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setIsDropping(true);
    } else if (e.type === "dragleave") {
      setIsDropping(false);
    }
  };

  const handleDrop = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDropping(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      if (validateLocalUpload(file)) {
        props.setFile(file);
        setFileError("");
      } else {
        setFileError("Invalid file type");
      }
    }
  };

  const uploadFile = async (e: any) => {
    const file = e.target.files[0];
    if (validateLocalUpload(file)) {
      props.setFile(file);
      setFileError("");
    } else {
      setFileError("Invalid file type");
    }
  };

  const cancelUpload = () => {
    setIsDropping(false);
    props.setFile(null);
  };

  return (
    <>
      <form
        className={props.isLocalUpload ? styles.dragDropSection : "d-none"}
        onSubmit={(e) => e.preventDefault()}
      >
        <span className={styles.fileTypeText}>Upload file (.tar .zip)</span>
        {!props.file && (
          <div
            className={`${
              // eslint-disable-next-line
              (isDropping ? styles.dropContainer : styles.dropZone,
              fileError ? styles.dropZoneError : styles.dropZone)
            }`}
            onDragOver={handleDragOver}
            onDragEnter={handleDragOver}
            onDragLeave={handleDragOver}
            onDrop={handleDrop}
          >
            {isDropping ? (
              <>
                <div>Drop file here</div>
                <div className={styles.addFileIcon}>
                  <img src={addFileIcon} alt="" />
                </div>
              </>
            ) : (
              <>
                <div className={styles.uploadImg}>
                  <img src={uploadIcon} alt="" />
                </div>
                <div className={styles.dragDropText}>
                  {fileError && (
                    <span className={styles.fileErrorMessage}>{fileError}</span>
                  )}
                  Drag & Drop or{" "}
                  <span
                    className={styles.chooseFileText}
                    onClick={() => inputRef?.current?.click()}
                  >
                    Choose A File{" "}
                  </span>
                </div>
                <input
                  type="file"
                  onChange={(e) => uploadFile(e)}
                  hidden
                  ref={inputRef}
                />
              </>
            )}
          </div>
        )}
        {props.file && (
          <div className={styles.loadingContainer}>
            <div className={styles.fileDetails}>
              <div className={styles.leftSide}>
                <div>
                  <img src={fileImage} alt="" />
                </div>
                <div className={styles.fileNameSection}>
                  <span className={styles.fileName}>{props.file.name}</span>
                  <span className={styles.fileSize}>
                    {formatBytes(props.file.size)}
                  </span>
                </div>
              </div>
              <div
                className={styles.cancelUpload}
                onClick={() => cancelUpload()}
              >
                <RxCross2 />
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
}

export default DragDrop;

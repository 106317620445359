import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Provider, connect, useDispatch, useSelector } from "react-redux";
import ReactModal from "react-modal";
import Modal from "react-modal";

import { Tab } from "types";

import { store, State, AppDispatch } from "redux/store";
import { selectTabs } from "redux/headerSlice";
import { userLogout } from "redux/authReducer";
import { getBackendVersion } from "redux/debugSlice";

import "./Header.css";

type HeaderButtonProps = {
  dispatch: any;
};

class HeaderButtons extends React.Component<
  HeaderButtonProps,
  { isUserModalOpen: boolean }
> {
  constructor(props: HeaderButtonProps) {
    super(props);
    this.state = {
      isUserModalOpen: false,
    };
    Modal.setAppElement("#root");
  }

  onCloseUserModal = () => {
    this.setState({ isUserModalOpen: false });
  };

  onClickUserModal = () => {
    this.setState({ isUserModalOpen: true });
  };

  renderUserModal() {
    const style = {
      content: {
        position: "relative" as React.CSSProperties["position"],
        maxWidth: "166px",
        inset: "56px 0 calc(100% - 56px) calc(100% - 216px)",
        backgroundColor: "#28303f",
        borderRadius: "4px",
        boxShadow: "0 0 10px 0 rgba(0,0,0,.75)",
        marginTop: "4px",
        border: "none",
      },
    };

    /* todo: add logout onClick */
    return (
      <Modal
        overlayClassName="user-modal-overlay"
        isOpen={this.state.isUserModalOpen}
        style={style}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.onCloseUserModal}
      >
        <div className="user-modal">
          <ul className="user-modal-menu--list">
            <li className="user-modal--item user-modal-item-Profile">
              Profile
            </li>
            <li
              className="user-modal--item user-modal-item-Logout"
              onClick={this.onLogOut()}
            >
              Log out
            </li>
          </ul>
        </div>
      </Modal>
    );
  }

  private onLogOut() {
    return () => {
      userLogout();
    };
  }

  render() {
    const dispatch = this.props.dispatch;
    return (
      <div>
        <div className="header-buttons">
          <button
            className="photo"
            onClick={() => {
              dispatch({ type: "ON_PHOTO_BTN_CLICKED" });
            }}
          ></button>
          <button
            className="filter"
            onClick={() => {
              dispatch({ type: "ON_FILTER_BTN_CLICKED" });
            }}
          ></button>
          <button
            className="change-view grid"
            onClick={() => {
              dispatch({ type: "ON_FILTER_BTN_CLICKED" });
            }}
          ></button>
          <button
            className="avatar"
            onClick={() => {
              this.onClickUserModal();
            }}
          ></button>
        </div>
        {this.renderUserModal()}
      </div>
    );
  }
}

function StagingVersionInfoModalContent() {
  const frontendVersion = useSelector(
    (state: State) => state.debug.frontendVersion
  );
  const backendVersion = useSelector(
    (state: State) => state.debug.backendVersion
  );
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getBackendVersion());
  }, [dispatch]);
  return (
    <div className="header-staging-info--modal-content">
      <div className="header-staging-info--modal-content--title">
        Staging Versions
      </div>
      <div className="header-staging-info--modal-content--versions">
        <div className="header-staging-info--modal-content--version">
          <span className="header-staging-info--modal-content--versions-key">
            Frontend Version:
          </span>
          <span className="header-staging-info--modal-content--versions-value">
            <a
              href={`https://github.com/visual-layer/vl-product-fe/tree/${frontendVersion}`}
              target="_blank"
              rel="noreferrer"
            >
              {frontendVersion === "main"
                ? "unknown"
                : frontendVersion.slice(0, 7)}
            </a>
          </span>
        </div>
        <div className="header-staging-info--modal-content--version">
          <span className="header-staging-info--modal-content--versions-key">
            Backend Version:
          </span>
          <span className="header-staging-info--modal-content--versions-value">
            <a
              href={`https://github.com/visual-layer/vl-product/tree/${backendVersion}`}
              target="_blank"
              rel="noreferrer"
            >
              {backendVersion === "main"
                ? "unknown"
                : backendVersion.slice(0, 7)}
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}

function StagingVersionInfo() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div
      className="header-staging-info"
      onClick={() => setIsModalOpen(!isModalOpen)}
    >
      ** STAGING **
      <ReactModal
        className="header-staging-info--modal"
        isOpen={isModalOpen}
        overlayClassName="header-staging-info--modal-overlay"
      >
        <StagingVersionInfoModalContent />
      </ReactModal>
    </div>
  );
}

type HeaderProps = {
  tabs: Tab[];
};

function Header(props: HeaderProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { tabs } = props;
  const activeTabName = tabs.find(
    (tab) => tab.path.split("/")[1] === location.pathname.split("/")[1]
  )?.name;
  const categories = tabs.map((tab, i) => {
    const classNames = `category ${tab.name === activeTabName ? "active" : ""}`;
    return (
      <li
        className={classNames}
        onClick={() => {
          navigate(tab.path);
        }}
        key={i}
      >
        {tab.name}
      </li>
    );
  });
  const showStagingInfo =
    process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_ENVIRONMENT === "STAGING";
  const versionInfo = showStagingInfo ? <StagingVersionInfo /> : null;
  return (
    <header className="header">
      <h2 className="header-logo">{versionInfo}</h2>
      <ul className="header-categories">{categories}</ul>
      <HeaderButtons {...{ dispatch }} />
    </header>
  );
}

function mapStateToProps(state: State) {
  return {
    tabs: selectTabs(state),
  };
}

// connects to the App component the state and the dispatch together
const ConnectedHeader = connect(mapStateToProps)(Header);

export const WrappedHeader = () => {
  return (
    <Provider store={store}>
      <ConnectedHeader />
    </Provider>
  );
};

import { createSelector } from "@reduxjs/toolkit";

const userDatasets = (state: any) => {
  return state.Userdatasets;
}

export const getLoading = createSelector(
  userDatasets, 
  (state: any) => {
    return state.isLoading;
  }
);

export const getDatasets = createSelector(userDatasets, (state: any) => {
  return state.datasets;
});

export const getSelectedDataset = createSelector(userDatasets, (state?: any) => {
  return state.selectedDataset;
});

import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import { State } from "redux/store";
import {
  getSampleData,
  getLoading,
  getDatasetId,
  getSelectedSampleDataset,
  getErrorText,
} from "redux/Datasets/selectors";
import {
  createDataset,
  fetchSampleData,
  setIsError,
  setErrorText,
} from "redux/Datasets/actions";

import Loader from "views/uikit/Loader";

import VLModal from "views/uikit/VLModal";
import SelectSampleData from "views/components/SelectSampleData";
import SelectSourceType from "views/components/SelectSourceType";
import UploadDataset from "views/components/UploadDataset";

import datasetIcon from "assets/img/dataset.svg";

import styles from "./style.module.scss";

function CreateDatasetModal(props: any) {
  //internal states
  const [selectedSource, setSelectedSource] = useState(0);
  const [sampleSourceState, setSampleSourceState] = useState(0);
  const [localSourceState, setLocalSourceState] = useState(0);
  const [s3SourceState, setS3SourceState] = useState(0);
  const [datasetName, setDatasetName] = useState("");
  const [s3Bucket, sets3Bucket] = useState("");
  const [errorFlag, setErrorFlag] = useState("");
  const [selectedSourceName, setSelectedSourceName] = useState("");
  const [file, setFile] = useState<any>(null);
  const [fileError, setFileError] = useState("");
  const [bucketNameError, setBucketNameError] = useState("");
  const [page, setPage] = useState(1);

  //props destructuring (from redux store)
  const { errorText } = props;

  const dispatch = useDispatch();

  //------------Debug commands----------------------//

  //-----------------------------------------------//

  useEffect(() => {
    dispatch(fetchSampleData() as any);
  }, [dispatch]);

  const handleModal = () => {
    props.setShowModal(false);
    props.createFirstDataset(true);
    resetModalStates();
  };

  //--------Code Refactoring--------//

  const nextStep = async () => {
    if (selectedSource === 1) {
      if (localSourceState) {
        await dispatch(createDataset(datasetName, null, null, file) as any);
        handleModal();
      } else {
        setPage(2);
        setLocalSourceState(localSourceState + 1);
      }
    } else if (selectedSource === 2) {
      if (s3SourceState) {
        await dispatch(createDataset(datasetName, s3Bucket, null, null) as any);
        handleModal();
      } else {
        setPage(2);
        setS3SourceState(s3SourceState + 1);
      }
    } else {
      if (sampleSourceState) {
        await dispatch(
          createDataset(
            datasetName,
            null,
            props.selectedSampleDataset?.id,
            null
          ) as any
        );
        handleModal();
      } else {
        setPage(2);
        setSampleSourceState(sampleSourceState + 1);
      }
    }
  };

  const previousStep = () => {
    if (selectedSource === 1) {
      setPage(1);
      setFile(null);
      setFileError("");
      setLocalSourceState(localSourceState - 1);
    } else if (selectedSource === 2) {
      dispatch(setErrorText(null));
      sets3Bucket("");
      setBucketNameError("");
      setPage(1);
      setS3SourceState(s3SourceState - 1);
    } else {
      setSelectedSourceName("");
      setPage(1);
      setSampleSourceState(sampleSourceState - 1);
    }
  };

  const resetModalStates = () => {
    setSelectedSource(0);
    setSampleSourceState(0);
    setLocalSourceState(0);
    setS3SourceState(0);
    setDatasetName("");
    sets3Bucket("");
    setFile(null);
    dispatch(setErrorText(null));
    setErrorFlag("");
    setFileError("");
    setBucketNameError("");
    setPage(1);
    dispatch(setIsError(false));
  };

  return (
    <VLModal
      {...props}
      isSampleDataSelected={sampleSourceState}
      resetModalStates={resetModalStates}
    >
      {props.isLoading && (
        <div className={styles.pAbsolute}>
          <Loader />
        </div>
      )}

      <div
        className={
          sampleSourceState
            ? styles.modalContentWithOverFlow
            : styles.modalContent
        }
      >
        {/* Modal Header */}
        <div className={styles.modalHeader}>
          <img src={datasetIcon} alt="" />
          <span>Create a new dataset</span>
        </div>
        {/* Modal Body (multiple classes based on dataset type) */}
        <div className={styles.modalBody}>
          <SelectSourceType
            errorFlag={errorFlag}
            setErrorFlag={setErrorFlag}
            selectedSource={selectedSource}
            onSelectSource={setSelectedSource}
            setSelectedSourceName={setSelectedSourceName}
            datasetName={datasetName}
            handleChange={setDatasetName}
            show={!sampleSourceState && !localSourceState && !s3SourceState}
          />
          <SelectSampleData
            selectedSourceName={selectedSourceName}
            datasetName={datasetName}
            show={sampleSourceState === 1}
          />
          <UploadDataset
            errorText={errorText}
            setErrorText={setErrorText}
            fileUrl={s3Bucket}
            setFileUrl={sets3Bucket}
            selectedSourceName={selectedSourceName}
            datasetName={datasetName}
            isLocalUpload={localSourceState === 1}
            show={s3SourceState === 1 || localSourceState === 1}
            setFile={setFile}
            file={file}
            fileError={fileError}
            setFileError={setFileError}
            bucketNameError={bucketNameError}
            setBucketNameError={setBucketNameError}
          />
        </div>
        <div className={styles.modalFooter}>
          <div>
            {(sampleSourceState > 0 ||
              s3SourceState > 0 ||
              localSourceState > 0) && (
              <div className={styles.backBtn} onClick={() => previousStep()}>
                Back
              </div>
            )}
          </div>
          <div className={styles.pagination}>
            <div
              className={page === 1 ? styles.circleBlack : styles.circleWhite}
            ></div>
            <div
              className={page === 2 ? styles.circleBlack : styles.circleWhite}
            ></div>
          </div>
          <div className={styles.nextBtnContainer}>
            <button
              className={
                !selectedSource ||
                (selectedSource && errorFlag !== "") ||
                datasetName === "" ||
                (sampleSourceState === 1 &&
                  props.selectedSampleName?.display_name === "") ||
                (s3SourceState === 1 &&
                  (errorText === null || errorText !== "")) ||
                (!file && localSourceState === 1)
                  ? styles.disabledNextBtn
                  : styles.nextBtn
              }
              onClick={() => nextStep()}
            >
              {page === 2 ? "Create" : "Next"}
            </button>
          </div>
        </div>
      </div>
    </VLModal>
  );
}

function mapStatesToProps(state: State) {
  return {
    sampleData: getSampleData(state),
    isLoading: getLoading(state),
    datasetId: getDatasetId(state),
    selectedSampleDataset: getSelectedSampleDataset(state),
    errorText: getErrorText(state),
  };
}

export default connect(mapStatesToProps)(CreateDatasetModal);

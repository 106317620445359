import { useEffect } from "react";

import crossImg from "assets/img/modalBlackCross.svg";

import styles from "./style.module.scss";

function VLModal(props: any) {
  // eslint-disable-next-line
  const closeModal = () => {
    props.setShowModal(false);
    props.resetModalStates();
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeModal();
        document.removeEventListener("keydown", handleKeyDown);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [closeModal]);

  return (
    <>
      <div
        className={
          props.showModal ? styles.modalMainContainer : styles.hideModal
        }
      >
        <div className={styles.modalInnerContainer}>
          <div className={styles.closeBtn}>
            <button onClick={() => closeModal()}>
              <img src={crossImg} alt="" />
            </button>
          </div>
          {props.children}
        </div>
      </div>
    </>
  );
}
export default VLModal;

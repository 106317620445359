import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { SET_DATASETS, IS_LOADING, SELECT_DATASET } from "./constants";
import { State } from "redux/store";

import { axiosGet, composeApiURL } from "helpers/utils";

import { User } from "types";

export const setLoader = (data: boolean) => {
  return {
    type: IS_LOADING,
    data,
  };
};

export const setUserDatasets = (data: any) => {
  return {
    type: SET_DATASETS,
    data,
  };
};

export const selectDataset = (data: any) => {
  return {
    type: SELECT_DATASET,
    data,
  };
};

export const fetchDataSets = (
  user: User
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    dispatch(setLoader(true));
    const state = getState();
    try {
      const url = composeApiURL(state.debug.serverUrl, "/user_datasets");
      const response = await axiosGet(url, user);
      dispatch(setLoader(false));
      dispatch(setUserDatasets(JSON.parse(response.data)));
    } catch (error) {
      dispatch(setLoader(false));
      console.log(error);
    }
  };
};

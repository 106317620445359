import React, { useCallback, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import { getRoot } from "redux/treeSlice";
import { selectUser } from "redux/authReducer";
import { AppDispatch, State } from "redux/store";
import {
  selectBreadcrumbs,
  onSelectDataset,
  onBreadcrumbClicked,
} from "redux/treeSlice";
import { getDatasets, getSelectedDataset } from "redux/UserDatasets/selectors";
import { fetchDataSets, selectDataset } from "redux/UserDatasets/actions";

import "./Clustplorer.css";

const Breadcrubms = ({
  breadcrumbs,
  selectedDataset,
}: {
  breadcrumbs: string[];
  selectedDataset: string;
}) => {
  const dispatch = useDispatch();
  const user = selectUser();
  const handleBreadCrumbClick = (i: number, breadcrumb: any) => {
    if (i < breadcrumbs.length - 1) {
      if (user) {
        const clickedNode = breadcrumb;
        dispatch(getRoot({ user, selectedDataset, clickedNode }) as any);
        dispatch(onBreadcrumbClicked(breadcrumb));
      }
    }
  };
  return (
    <div className="nav-header--breadcrumbs">
      {breadcrumbs.map((breadcrumb, i) => {
        return (
          <div
            className="breadcrumb"
            key={i}
            onClick={() => handleBreadCrumbClick(i, breadcrumb)}
          >
            <div className="breadcrumb-title">{breadcrumb}</div>
          </div>
        );
      })}
    </div>
  );
};

function DatasetsSelector(props: { datasets: Object }) {
  const dispatch = useDispatch<AppDispatch>();
  const { datasets = {} } = props;
  useEffect(() => {
    const user = selectUser();
    dispatch(fetchDataSets(user!));
  }, [dispatch]);

  const onDatasetSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedDataset = e.target?.value;
      const user = selectUser();
      if (user) {
        const clickedNode = "";
        dispatch(selectDataset(selectedDataset));
        dispatch(getRoot({ user, selectedDataset, clickedNode }));
        dispatch(onSelectDataset());
      }
    },
    [dispatch]
  );

  return (
    <select className="nav-datasets" onChange={onDatasetSelect}>
      <option value="">Select dataset</option>
      {Object.keys(datasets || {}).map((key, index) => {
        const val = (props.datasets as any)[key].name;
        return (
          <option key={index} value={key}>
            {val}
          </option>
        );
      })}
    </select>
  );
}

type NavHeaderProps = {
  breadcrumbs: string[];
  datasets: Object;
  selectedDataset: string;
};

function NavHeader(props: NavHeaderProps) {
  return (
    <div className="nav-header">
      <div className="nav-header--left">
        <button className="nav-header--filter-btn"></button>
        <Breadcrubms
          breadcrumbs={props.breadcrumbs}
          selectedDataset={props.selectedDataset}
        />
      </div>
      <div className="nav-header--right">
        <div className="nav-header--sorting-section">
          <div className="nav-header--sorting-title">Datasets:</div>
          <div className="nav-header--sorting-type">
            <DatasetsSelector {...{ datasets: props.datasets }} />
          </div>
        </div>
        <div className="nav-header--sorting-section">
          <div className="nav-header--sorting-title">Sorting:</div>
          <div className="nav-header--sorting-type">Cluster size</div>
        </div>
        <div className="nav-header--arrow"></div>
        <button className="nav-header--info-btn"></button>
      </div>
    </div>
  );
}

function mapStateToClustplorerHeaderProps(state: State) {
  return {
    breadcrumbs: selectBreadcrumbs(state),
    datasets: getDatasets(state),
    selectedDataset: getSelectedDataset(state),
  };
}

// connects to the App component the state and the dispatch together
const ClustplorerHeader = connect(mapStateToClustplorerHeaderProps)(NavHeader);

export default ClustplorerHeader;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";

import { State } from "./store";
import { selectUser } from "./authReducer";

import { axiosGet, composeApiURL } from "helpers/utils";

const initialState = {
  animationEnabled: false,
  smartImageLoading: true,
  duplicateImages: true,
  numberOfImagesPerClusterPreview: 16,
  serverUrl: getInitialServerUrl(),
  frontendVersion: process.env.REACT_APP_VERSION || "main",
  backendVersion: "main",
};

export const getBackendVersion = createAsyncThunk(
  "debug/getBackendVersion",
  async (payload, options) => {
    const state = options.getState();
    const url = composeApiURL((state as State).debug.serverUrl, "/version");
    const response = await axiosGet(url, selectUser()!);
    return response.data;
  }
);

const debugSlice = createSlice({
  name: "debug",
  initialState,
  reducers: {
    onToggleAnimation(state) {
      state.animationEnabled = !state.animationEnabled;
    },
    onToggleSmartImageLoading(state) {
      state.smartImageLoading = !state.smartImageLoading;
    },
    onChangeNumberOfImagesPerClusterPreview(state, action) {
      state.numberOfImagesPerClusterPreview = action.payload;
    },
    onChangeServerUrl(state, action) {
      state.serverUrl = action.payload;
    },
    onToggleDuplicateImage(state) {
      state.duplicateImages = !state.duplicateImages;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBackendVersion.fulfilled, (state, action) => {
      state.backendVersion = action.payload.version;
    });
  },
});

export default debugSlice.reducer;

export const {
  onToggleAnimation,
  onToggleSmartImageLoading,
  onChangeNumberOfImagesPerClusterPreview,
  onChangeServerUrl,
  onToggleDuplicateImage,
} = debugSlice.actions;

function getInitialServerUrl(): string {
  const storedHostUrl = localStorage.getItem("hostUrl");
  if (storedHostUrl !== null && storedHostUrl !== "") {
    return storedHostUrl;
  } else if (window.location.host.startsWith("localhost")) {
    return process.env.REACT_APP_API_ENDPOINT || "http://localhost:9999";
  } else {
    return window.location.protocol + "//" + window.location.host;
  }
}

export const thunkChangeServerUrl = (
  serverUrl: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: any, getState: any) => {
    if (serverUrl !== "") {
      try {
        new URL(serverUrl);
      } catch (e) {
        //invalid url - do nothing
        return;
      }
    }
    localStorage.setItem("hostUrl", serverUrl);
    dispatch(onChangeServerUrl(serverUrl));
  };
};

import React from "react";
import { useEffect, useRef, useLayoutEffect } from "react";
import { connect, Provider, useDispatch, useSelector } from "react-redux";
import { useStore } from "react-redux";
import { gsap } from "gsap";
import placeholderImage from "assets/img/blank.png";

import { TreeNode, User, ItemCenter, Status } from "types";

import { store } from "redux/store";
import {
  selectStatus,
  selectBreadcrumbs,
  selectCurrentNode,
  selectClickedNode,
} from "redux/treeSlice";
import { onTreeNodeClicked, selectItemCenter, getRoot } from "redux/treeSlice";
import {
  getLoading,
  getDatasets,
  getSelectedDataset,
} from "redux/UserDatasets/selectors";
import { selectUser } from "redux/authReducer";
import { State } from "redux/store";

import LoggedInPage from "views/pages/LoggedInPage/LoggedInPage";
import ImageLoader from "views/components/ImageLoader";

import ClustplorerHeader from "./ClustplorerHeader";

import "./Clustplorer.css";

type ClusterItemMetadataProps = {
  numOfImages?: number;
  clusterId?: string;
};

class ClusterItemMetadata extends React.Component<ClusterItemMetadataProps> {
  render() {
    const clusterId = this.props.clusterId;
    const numOfImages = this.props.numOfImages;
    // until we have a support for multiple cluster trees, we'll piggy-back on cluster id (in lieu of cluster naming algo):
    // - if cluster id is alphabetic, then we'll use it as cluster title.
    // - else, we'll just use number of images in cluster as cluster title
    const regex = /[a-zA-Z]/;
    let clusterMetadataText = ``;
    if (clusterId && regex.test(clusterId)) {
      clusterMetadataText = clusterId;
    } else {
      clusterMetadataText = `${numOfImages} images`;
    }
    if (!!numOfImages === false) {
      clusterMetadataText = ``;
    }
    return (
      <div className="cluster-item-metadata">
        <div className="cluster-item--left">
          <label className="cluster-item--container">
            <input type="checkbox" />
            <span className="checkmark" />
          </label>
          <div className="cluster-item--total-images">
            {clusterMetadataText}
          </div>
        </div>
        <div className="cluster-item--right">
          <div className="cluster-item--media" />
          <div className="cluster-item--message" />
          <div className="cluster-item--star" />
          <div className="cluster-item--dots" />
        </div>
      </div>
    );
  }
}

type ClusterItemDataAnimationConfig = {
  timeline: GSAPTimeline;
  center: ItemCenter;
  name: string;
};

function getItemCenter(el: HTMLElement | null): ItemCenter | null {
  if (el === null) {
    return null;
  }
  const rect = el.getBoundingClientRect();
  const centerX = rect.left + rect.width / 2;
  const centerY = rect.top + rect.height / 2;
  return { x: centerX, y: centerY };
}

function getFromDirection(fromCenter: ItemCenter, toCenter: ItemCenter) {
  return { x: fromCenter.x - toCenter.x, y: fromCenter.y - toCenter.y };
}

function useAnimationSubclusterIncoming(
  target: React.RefObject<any>,
  animation?: ClusterItemDataAnimationConfig
) {
  const status = useSelector(
    (state: State) => selectStatus(state)
  );

  useLayoutEffect(() => {
    if (target.current && animation && status === Status.Loaded) {
      const ctx = gsap.context(() => {
        const timeline = animation.timeline;
        const { x, y } = getFromDirection(
          animation.center,
          getItemCenter(target.current)!
        );
        timeline.from(
          target.current,
          { x, y, autoAlpha: 0, duration: 0.5 },
          animation.name
        );
      });
      return () => ctx.revert();
    }
  }, [target, animation, status]);
}

type ClusterItemDataProps = {
  clusterId: string;
  image: string;
  previews: string[];
  selectedDataset: string;
  clickedNode: string;
};

const updateSrc = (src: string): string => {
  // parse src to URL
  const url = new URL(src);
  const s3ToCdnMap = Object.create({
    "vl-coco-2017.s3.amazonaws.com": "d7dvebq6iszwg.cloudfront.net",
    "vl-laion.s3.amazonaws.com": "d1wggmyhfjiya4.cloudfront.net",
  });
  if (url.hostname in s3ToCdnMap) {
    url.hostname = s3ToCdnMap[url.hostname];
    url.protocol = "https";
    src = url.toString();
    src = src.split("?")[0];
  }
  return src;
};

const ClusterItemData = (props: ClusterItemDataProps) => {
  const dispatch = useDispatch();
  const user = selectUser();
  let { clusterId, image, previews = [], selectedDataset } = props;

  const handleNodeClick = (ev: any) => {
    if (user) {
      const clickedNode = clusterId;
      dispatch(getRoot({ user, selectedDataset, clickedNode }) as any);
      dispatch(onTreeNodeClicked({ clusterId, clickedItemCenter: getItemCenter(ev.target) }) as any);
    }
  };
  const el = useRef<HTMLDivElement>(null);
  const store = useStore().getState() as State;
  const numberOfImagesPerClusterPreview = useSelector(
    (state: State) => state.debug.numberOfImagesPerClusterPreview
  );

  if (previews.length === 0) {
    return <div />;
  }
  if (previews.length < numberOfImagesPerClusterPreview) {
    previews = previews.concat(
      new Array(numberOfImagesPerClusterPreview - previews.length)
        .fill(0)
        .map((n) => placeholderImage)
    );
  }
  const smartImageLoading = store.debug.smartImageLoading;

  if (numberOfImagesPerClusterPreview === 13) {
    return (
      <div
        ref={el}
        className="cluster-item-data"
        onClick={(ev) => handleNodeClick(ev)}
      >
        {[1].map((i) => {
          const src = smartImageLoading ? updateSrc(image) : image;
          return (
            <ImageLoader
              src={src}
              className="cluster-item--image big-image"
              key={i}
            />
          );
        })}
        {previews.slice(1, numberOfImagesPerClusterPreview).map((image, i) => {
          const src = smartImageLoading ? updateSrc(image) : image;
          return (
            <ImageLoader src={src} className="cluster-item--image" key={i} />
          );
        })}
      </div>
    );
  } else {
    //numberOfColumns should be rounding of square root of numberOfImagesPerClusterPreview
    const numberOfColumns = Math.floor(
      Math.sqrt(numberOfImagesPerClusterPreview)
    );

    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)`,
          gridGap: "10px",
        }}
        ref={el}
        className="cluster-item-data--multiple"
        onClick={(ev) => handleNodeClick(ev)}
      >
        {previews.slice(0, numberOfImagesPerClusterPreview).map((image, i) => {
          const src = smartImageLoading ? updateSrc(image) : image;
          return (
            <ImageLoader src={src} className="cluster-item--image" key={i} />
          );
        })}
      </div>
    );
  }
};

function mapStateToClusterItemDataProps(state: any) {
  return {
    selectedDataset: getSelectedDataset(state),
    clickedNode: selectClickedNode(state),
  };
}

// connects to the App component the state and the dispatch together
const ConnectedClusterItemData = connect(mapStateToClusterItemDataProps)(
  ClusterItemData
);

type Cluster = {
  metadata: ClusterItemMetadataProps;
  data: ClusterItemDataProps;
  animation?: ClusterItemDataAnimationConfig;
};

function ClusterItem(props: Cluster) {
  const el = useRef<HTMLDivElement>(null);
  useAnimationSubclusterIncoming(el, props.animation);

  return (
    <div ref={el} className="cluster-item">
      <div className="section">
        <ClusterItemMetadata {...props.metadata} />
        <ConnectedClusterItemData {...props.data} />
      </div>
    </div>
  );
}

const getIthPreviews = (node: TreeNode, i: number): Array<string> => {
  if (i < 0) {
    return [];
  }
  if (i === 0 || node.components === undefined) {
    return [node.preview || node.src || ""];
  }
  const treeNodeWithPreviews = node.components.reduce(
    (acc, c) => {
      acc.previews = acc.previews?.concat(getIthPreviews(c, i - 1)) || [];
      return acc;
    },
    { previews: [] as Array<string> } as TreeNode
  );
  return treeNodeWithPreviews.previews!;
};

const computeNodePreviews = (
  node: TreeNode,
  requiredLength: number,
  duplicateImages: boolean
): Array<string> => {
  const MAX_RECURSION_DEPTH = 4;
  const previews = new Array<string>();
  for (let i = 0; i < MAX_RECURSION_DEPTH; i++) {
    const prevs = getIthPreviews(node, i);
    if (!prevs || prevs.length === 0) {
      break;
    }
    previews.push(...prevs);

    // filter empty and duplicates
    previews.filter((p, i) => p !== "" && previews.indexOf(p) === i);
    if (
      previews.length >= requiredLength ||
      previews.length >= node.num_images
    ) {
      break;
    }
  }
  return previews;
};

type ClustersViewProps = {
  currentNode: TreeNode;
  animation?: ClusterItemDataAnimationConfig;
};

const isLeafNode = (node: TreeNode): boolean =>
  node.components &&
  node.components.length > 0 &&
  node.components[0].src !== undefined;

function ClustersView(props: ClustersViewProps) {
  const numberOfImagesPerClusterPreview = useSelector(
    (state: State) => state.debug.numberOfImagesPerClusterPreview
  );

  const duplicateImages = useSelector((state: State) => state.debug.duplicateImages);

  const nodePreviews = (currentNode: TreeNode): Array<string> => {
    let previews: Array<string> = [];
    if (currentNode.previews) {
      previews = currentNode.previews!.slice(
        0,
        numberOfImagesPerClusterPreview
      );
    } else {
      previews = computeNodePreviews(
        currentNode,
        numberOfImagesPerClusterPreview,
        duplicateImages
      )
    };

    // if we don't have enough previews, we fill the rest with duplicate random previews
    if (previews.length < numberOfImagesPerClusterPreview && duplicateImages) {
      previews.push(
      ...new Array(numberOfImagesPerClusterPreview - previews.length)
        .fill(0)
        .map((n) => previews[Math.floor(Math.random() * previews.length)])
      );    
    }
    return previews;
  }

  const treeToItemProps = (currentNode: TreeNode): Cluster | null => {
    const previews = nodePreviews(currentNode);
    const image = currentNode.preview || currentNode.src || previews[0] || "";
    if (!image || !previews) {
      console.error(`no image or previews in cluster ${currentNode.name}`);
      return null;
    } else {
      return {
        metadata: {
          numOfImages: currentNode.num_images,
          clusterId: currentNode.component_id,
        },
        data: {
          clusterId: currentNode.name || currentNode.component_id,
          image,
          previews,
          selectedDataset: "",
          clickedNode: "",
        },
      };
    }
  };

  const clusters = props.currentNode.components
    .map(treeToItemProps)
    .filter((c) => c !== null);
  return (
    <div className="clusters-view">
      {clusters
        .sort(
          (c1, c2) =>
            (c2?.metadata.numOfImages || 0) - (c1?.metadata.numOfImages || 0)
        )
        .map((c, i) => {
          return <ClusterItem animation={props.animation} {...c!} key={i} />;
        })}
    </div>
  );
}

type SubClusterImageProps = {
  image: string;
  animation?: ClusterItemDataAnimationConfig;
};

function LeafClusterImage({ image, animation }: SubClusterImageProps) {
  const el = useRef<HTMLDivElement>(null);
  useAnimationSubclusterIncoming(el, animation);
  const smartImageLoading = (useStore().getState() as State).debug
    .smartImageLoading;
  if (smartImageLoading) {
    image = updateSrc(image);
  }
  return (
    <ImageLoader
      src={image}
      className="sub-cluster--preview"
      innerStyle={{ position: "relative", width: "100%", height: "100%" }}
    />
  );
}

function LeafClustersView(props: ClustersViewProps) {
  const { currentNode } = props;
  return (
    <div className="sub-cluster-view">
      {currentNode.components.map((c, i) => {
        return (
          <LeafClusterImage
            animation={props.animation}
            image={c.src || ""}
            key={i}
          />
        );
      })}
    </div>
  );
}

function newClusterItemAnimation(
  clickedItemCenter: ItemCenter | null
): ClusterItemDataAnimationConfig | undefined {
  if (clickedItemCenter === null) {
    return undefined;
  }
  const name = Math.random().toString(36).substring(7);
  const timeline = gsap.timeline();
  timeline.add(name);
  return {
    timeline,
    name,
    center: clickedItemCenter,
  };
}

type ClustplorerGridProps = {
  currentNode: TreeNode;
  clickedItemCenter: ItemCenter | null;
  animationEnabled: boolean;
  selectedDataset: string;
  clickedNode: string;
};

function ClustplorerGrid({
  currentNode,
  clickedItemCenter,
  animationEnabled,
}: ClustplorerGridProps) {
  const animation = animationEnabled
    ? newClusterItemAnimation(clickedItemCenter)
    : undefined;
  if (isLeafNode(currentNode)) {
    return <LeafClustersView currentNode={currentNode} />;
  } else {
    return <ClustersView currentNode={currentNode} animation={animation} />;
  }
}

function Clustplorer({
  user,
  currentNode,
  status,
  clickedItemCenter,
  animationEnabled,
  selectedDataset,
  clickedNode,
}: ClustplorerProps) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!user?.token) {
      console.error("User not logged in");
      return;
    } else if (status === Status.Init) {
      dispatch(getRoot({ user, selectedDataset, clickedNode }) as any);
    }
  }, [dispatch, status, user, selectedDataset, clickedNode, currentNode]);

  return (
    <div className="clustplorer">
      <ClustplorerHeader />
      {currentNode && (
        <ClustplorerGrid
          currentNode={currentNode}
          clickedItemCenter={clickedItemCenter}
          animationEnabled={animationEnabled}
          selectedDataset={selectedDataset}
          clickedNode={clickedNode}
        />
      )}
    </div>
  );
}

type ClustplorerProps = {
  user: User | null;
  status: string;
  currentNode?: TreeNode;
  breadcrumbs: Array<string>;
  clickedItemCenter: ItemCenter | null;
  animationEnabled: boolean;
  isLoading: boolean;
  datasets: Object;
  selectedDataset: string;
  clickedNode: string;
};

function mapStateToClustplorerProps(state: any) {
  return {
    user: selectUser(state),
    status: selectStatus(state),
    breadcrumbs: selectBreadcrumbs(state),
    currentNode: selectCurrentNode(state),
    clickedItemCenter: selectItemCenter(state),
    animationEnabled: state.debug.animationEnabled,
    isLoading: getLoading(state),
    datasets: getDatasets(state),
    selectedDataset: getSelectedDataset(state),
    clickedNode: selectClickedNode(state),
  };
}

// connects to the App component the state and the dispatch together
const ConnectedClustplorer = connect(mapStateToClustplorerProps)(Clustplorer);

const WrappedClustplorer = () => {
  return (
    <Provider store={store}>
      <ConnectedClustplorer />
    </Provider>
  );
};

export default function ClustplorerPage() {
  return (
    <LoggedInPage>
      <WrappedClustplorer />
    </LoggedInPage>
  );
}

import React from 'react';

export type ImageLoaderProps = {
    children?: any;
    src: string;
    duration?: string;
    ease?: string;
    className?: string;
    innerStyle?: any;
    style?: any;
}

class ImageLoader extends React.Component<ImageLoaderProps, any> {
  private observer: any ;
  private preloader: any ;
  private el: any ;
  constructor(props: ImageLoaderProps) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    this.setPreloader();
  }

  setPreloader() {
    this.preloader = new Image();
    this.preloader.onload = () => this.setState({
      loaded: true,
    });
    this.preloader.src = this.props.src
  }

  componentWillUnmount() {
    if (this.preloader) this.preloader.onload = null;
  }

  render() {
    const backgroundSize = this.props.innerStyle && this.props.innerStyle.backgroundSize ? this.props.innerStyle.backgroundSize : "cover";
    const backgroundPosition = this.props.innerStyle && this.props.innerStyle.backgroundPosition ? this.props.innerStyle.backgroundPosition : "center";
    const backgroundRepeat = this.props.innerStyle && this.props.innerStyle.backgroundRepeat ? this.props.innerStyle.backgroundRepeat : "no-repeat";

    const defaultImageStyle = {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundImage: this.state.loaded ? `url(${this.props.src})` : undefined,
      backgroundSize: backgroundSize,
      backgroundPosition: backgroundPosition,
      backgroundRepeat: backgroundRepeat,
      transition: `opacity ${this.props.duration || '300ms'} ${this.props.ease || 'cubic-bezier(0.215, 0.61, 0.355, 1)'}`,
      opacity: this.state.loaded ? 1 : 0
    }
    const imageStyle = this.props.innerStyle ? { ...defaultImageStyle, ...this.props.innerStyle } : defaultImageStyle;

    return (
      <div
        // Required: Relative, absolute, or fixed position
        // Required: Width & height (explicitly or via top/right/bottom/left)
        // Optional: Background color or placeholder image
        className={this.props.className}
        style={{ ...this.props.style }}
        ref={(el) => this.el = el}
      >
        <div style={imageStyle}></div>
        {this.props.children}
      </div>
    );
  }
}

export default ImageLoader;

import { createSelector } from "@reduxjs/toolkit";

const state = (state: any) => state.datasets;

export const getLoading = createSelector(state, (state: any) => {
  return state.isLoading;
});

export const getContentLoading = createSelector(state, (state: any) => {
  return state.isContentLoading;
});

export const getDatasets = createSelector(state, (state: any) => {
  return state.datasets;
});

export const getSampleData = createSelector(state, (state: any) => {
  return state.sampleData;
});

export const getDatasetId = createSelector(state, (state: any) => {
  return state.datasetId;
});

export const getSelectedSampleDataset = createSelector(state, (state: any) => {
  return state.selectedSampleDataset;
});

export const getIsError = createSelector(state, (state: any) => {
  return state.isError;
});

export const getProfileUrl = createSelector(state, (state: any) => {
  return state.profileURL;
});

export const getErrorText = createSelector(state, (state: any) => {
  return state.errorText;
});

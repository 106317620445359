import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ClustplorerPage from "views/pages/Clustplorer/Clustplorer";
import DatasetInventory from "views/pages/DatasetInventory";
import DebugPage from "views/pages/DebugPage";
import Login from "views/pages/Login";

import "./App.scss";

export default function RouterApp() {
  const [showModal, setShowModal] = useState(false);
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <DatasetInventory
              showModal={showModal}
              setShowModal={setShowModal}
            />
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/clusters" element={<ClustplorerPage />} />
        <Route
          path="/datasets"
          element={
            <DatasetInventory
              showModal={showModal}
              setShowModal={setShowModal}
            />
          }
        />
        <Route path="/debug" element={<DebugPage />} />
      </Routes>
    </BrowserRouter>
  );
}

// Action Types
const PREFIX = "Datasets";
export const SET_DATASETS = `${PREFIX}_SET_DATASETS`;
export const IS_LOADING = `${PREFIX}_IS_LOADING`;
export const IS_CONTENT_LOADING = `${PREFIX}_IS_CONTENT_LOADING`;
export const SET_SAMPLE_DATA = `${PREFIX}_SET_SAMPLE_DATA`;
export const SET_DATASET_ID = `${PREFIX}_SET_DATASET_ID`;
export const SET_SELECTED_SAMPLE_DATASET = `${PREFIX}_SET_SELECTED_SAMPLE_DATASET`;
export const IS_ERROR = `${PREFIX}_IS_ERROR`;
export const ERROR_TEXT = `${PREFIX}_ERROR_TEXT`;
export const SET_PROFILE_URL = `${PREFIX}_SET_PROFILE_URL`;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { selectUser } from "redux/authReducer";

import ToastWrapper from "views/components/ToastWrapper";
import Sidebar from "./Sidebar";

import styles from "./style.module.scss";

function Layout(props: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = selectUser();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate, dispatch]);

  return (
    <>
      <ToastWrapper />
      <div className={styles.mainWrapper}>
        <div className={styles.innerWrapper}>
          <Sidebar />
          <div className={styles.content}>
            <div className={styles.body}>{props.children}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;

const LoginButton = (props: any) => {
  const {
    className,
    icon,
    text,
  }: { className: string; icon: string; text: string } = props;

  return (
    <button className={"login-with-button " + className}>
      <div className="login-with-icon">
        <img src={icon} alt="logo" />
      </div>
      <div className="login-button-text">
        <span>{text}</span>
      </div>
    </button>
  );
};

export default LoginButton;

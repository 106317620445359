import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect, Provider, useDispatch } from "react-redux";

import { User } from "types";

import { store } from "redux/store";
import { selectUser } from "redux/authReducer";
import { selectStatus } from "redux/treeSlice";

import Loader from "views/uikit/Loader";
import { WrappedHeader } from "./Header";

export type LoggedInPageProps = {
  children: React.ReactNode;
  user: User | null;
  status: string;
};

function LoggedInPageContent({ children, user, status }: LoggedInPageProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate, dispatch]);
  return (
    <div id="app">
      {status === "Loading" && (
        <div className="loader-bg">
          <Loader />
        </div>
      )}
      <WrappedHeader />
      <div className="main-container">
        <div className="content-block-wrapper">
          <div className="content-block">{children}</div>
        </div>
      </div>
    </div>
  );
}

function mapStateToClustplorerProps(state: any) {
  return {
    user: selectUser(state),
    status: selectStatus(state),
  };
}

const ConnectedLoggedInPage = connect(mapStateToClustplorerProps)(
  LoggedInPageContent
);

export default function LoggedInPage(props: { children: React.ReactNode }) {
  return (
    <Provider store={store}>
      <ConnectedLoggedInPage children={props.children} />
    </Provider>
  );
}

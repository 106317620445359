import axios from "axios";
import Cookies from "js-cookie";

import { userLogout } from "redux/authReducer";

const token = Cookies.get("user_token");
const origin = Cookies.get("user_origin");

const axiosInstance =
  token && origin
    ? axios.create({
        baseURL: process.env.REACT_APP_API_ENDPOINT,
        headers: {
          Authorization: "Bearer " + token,
          "x-user-origin": origin,
          "Content-Type": "multipart/form-data",
        },
      })
    : axios;

axiosInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      userLogout();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

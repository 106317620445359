import Button from "react-bootstrap/Button";

import styles from "./style.module.scss";

function VLButton(props: any) {
  return (
    <Button className={styles.vlButton} onClick={() => props.onClick()}>
      {props.text}
    </Button>
  );
}

export default VLButton;

import {
  SET_DATASETS,
  IS_LOADING,
  SET_SAMPLE_DATA,
  IS_CONTENT_LOADING,
  SET_DATASET_ID,
  SET_SELECTED_SAMPLE_DATASET,
  IS_ERROR,
  ERROR_TEXT,
  SET_PROFILE_URL,
} from "./constants";

const INITIAL_STATE = {
  isLoading: false,
  isContentLoading: false,
  datasets: [],
  sampleData: null,
  datasetId: null,
  selectedSampleDataset: null,
  isError: false,
  errorText: null,
  profileURL: "",
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_DATASETS:
      return {
        ...state,
        datasets: action.data,
      };

    case IS_LOADING:
      return {
        ...state,
        isLoading: action.data,
      };

    case IS_CONTENT_LOADING:
      return {
        ...state,
        isContentLoading: action.data,
      };

    case SET_SAMPLE_DATA:
      return {
        ...state,
        sampleData: action.data,
      };

    case SET_DATASET_ID:
      return {
        ...state,
        datasetId: action.data,
      };

    case SET_SELECTED_SAMPLE_DATASET:
      return {
        ...state,
        selectedSampleDataset: action.data,
      };

    case IS_ERROR:
      return {
        ...state,
        isError: action.data,
      };
    case SET_PROFILE_URL:
      return {
        ...state,
        profileURL: action.data,
      };
    case ERROR_TEXT:
      return {
        ...state,
        errorText: action.data,
      };
    default:
      return state;
  }
};

export default reducer;

import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";

import { store } from "redux/store";
import reportWebVitals from "reportWebVitals";

import RouterApp from "App";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <RouterApp />
  </Provider>
);

reportWebVitals();

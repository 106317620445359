export const StatsColors = ["#D85829", "#ECB13A", "#B2BAC9"];
export const StatsType = ["H", "M", "L"];
export const handleQualityScoreColor = (value: number) => {
  if (value >= 0 && value <= 50) {
    return "#D85829";
  } else if (value >= 51 && value <= 80) {
    return "#ECB13A";
  } else {
    return "#B2BAC9";
  }
};

import "./Toggle.css";

type VlToggleProps = {
  label: string;
  isToggled: boolean;
  onClick: (e: any) => void;
};

export default function VlToggle({ label, isToggled, onClick }: VlToggleProps) {
  return (
    <div className="vl-toggle-wrapper">
      <label className="vl-toggle">
        <input
          className="vl-toggle"
          type="checkbox"
          defaultChecked={isToggled}
          onClick={onClick}
        />
        <span className="vl-toggle" />
      </label>
      <strong className="vl-toggle">{label}</strong>
    </div>
  );
}

import { createSlice, createSelector } from "@reduxjs/toolkit";

import { Tab, TabBasePath, TabName } from "types";

const DebugMode = true;

const initialTabs: Array<Tab> = [
  {
    name: TabName.Explorer,
    path: TabBasePath.Explorer,
  },
  ...(DebugMode
    ? [
        {
          name: TabName.Debug,
          path: TabBasePath.Debug,
        },
      ]
    : []),
];

const initialState = {
  backendVersion: "",
  frontendVersion: process.env.REACT_APP_VERSION,
  tabs: initialTabs,
};

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {},
});

export default headerSlice.reducer;

const selectSelf = (state: any) => state.header;

export const selectTabs = createSelector(selectSelf, (header: any) => {
  return header.tabs;
});

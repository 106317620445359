import { useState } from "react";

import VLAvatar from "views/uikit/VLAvatar";
import ProfileModal from "views/components/ProfileModal";

import filter from "assets/img/filter-menu-open.svg";

import styles from "./style.module.scss";

function Sidebar() {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarContainer}>
        <div className={styles.sidebarTop}>
          <div className={styles.vlText}>VL</div>
          <div className={styles.icons}>
            <div className={styles.filterIcon}>
              <img alt="" src={filter} />
            </div>
          </div>
        </div>

        <div className={styles.sidebarBottom}>
          <div
            className={styles.avatarContainer}
            onClick={() => {
              setShowModal(true);
            }}
          >
            <VLAvatar size="small" />
          </div>
        </div>
        <ProfileModal showModal={showModal} setShowModal={setShowModal} />
      </div>
    </div>
  );
}

export default Sidebar;

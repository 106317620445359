import { useNavigate } from "react-router-dom";
import {
  useStore,
  useDispatch,
  useSelector,
  connect,
  Provider,
} from "react-redux";
import { FileUploader } from "react-drag-drop-files";

import { onUploadedJson } from "redux/treeSlice";
import { State } from "redux/store";
import {
  thunkChangeServerUrl,
  onChangeNumberOfImagesPerClusterPreview,
  onToggleAnimation,
  onToggleSmartImageLoading,
  onToggleDuplicateImage,
} from "redux/debugSlice";
import { store } from "redux/store";
import { AppDispatch } from "redux/store";

import LoggedInPage from "views/pages/LoggedInPage/LoggedInPage";
import VlToggle from "views/uikit/Toggle";
import VlSlider from "views/uikit/Slider";

import "./debugPage.css";

type DebugViewProps = {
  animationEnabled: boolean;
};

function DebugView(props: DebugViewProps) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleChange = (fileList: Array<any>) => {
    const file = fileList[0];

    const reader = new FileReader();
    reader.onload = (e: any) => {
      var obj = JSON.parse(e.target.result);
      dispatch(onUploadedJson(obj));
      navigate("/clusters");
    };
    reader.readAsText(file);
  };
  const store = useStore().getState() as State;
  const smartImageLoading = store.debug.smartImageLoading;
  const numberOfImagesPerClusterPreview = useSelector(
    (state: State) => state.debug.numberOfImagesPerClusterPreview
  );
  const duplicateImages = useSelector(
    (state: State) => state.debug.duplicateImages
  );
  const serverUrl = useSelector((state: State) => state.debug.serverUrl);
  return (
    <div className="debug-view">
      <FileUploader
        multiple={true}
        handleChange={handleChange}
        name="file"
        types={["json", "JSON"]}
        maxSize={1000}
        classes="debug-view file-uploader"
      />
      <div className="debug-view buttons-section">
        {VlToggle({
          label: "Animation Enabled",
          isToggled: props.animationEnabled,
          onClick: () => {
            dispatch(onToggleAnimation());
          },
        })}
        {VlToggle({
          label: "Smart image loading",
          isToggled: smartImageLoading,
          onClick: () => {
            dispatch(onToggleSmartImageLoading());
          },
        })}
        {VlToggle({
          label: "Duplicate images",
          isToggled: duplicateImages,
          onClick: () => {
            dispatch(onToggleDuplicateImage());
          },
        })}
        {VlSlider({
          value: numberOfImagesPerClusterPreview,
          onValueChange: (value: number) => {
            dispatch(onChangeNumberOfImagesPerClusterPreview(value));
          },
          label: "Number of images in cluster preview",
        })}
        {
          // add text input for server url
          <div className="debug-view-server-url-wrapper">
            <input
              className="debug-view-server-url-input"
              type="text"
              value={serverUrl}
              onChange={(e) => {
                dispatch(thunkChangeServerUrl(e.target.value));
              }}
            />
            <label className="debug-view-server-url-label">
              Server URL (https://staging-visual-layer.link)
            </label>
          </div>
        }
      </div>
    </div>
  );
}

// connects to the App component the state and the dispatch together
const ConnectedDebugView = connect((state: any) => {
  return {
    animationEnabled: state.debug.animationEnabled,
  };
})(DebugView);

const WrappedDebugView = () => {
  return (
    <Provider store={store}>
      <ConnectedDebugView />
    </Provider>
  );
};

export default function DebugPage(props: any) {
  return (
    <LoggedInPage>
      <WrappedDebugView {...props} />
    </LoggedInPage>
  );
}

import { configureStore } from "@reduxjs/toolkit";

import treeReducer from "./treeSlice";
import headerReducer from "./headerSlice";
import debugReducer from "./debugSlice";
import userDatasetReducer from "./UserDatasets/reducer";
import datasetReducer from "./Datasets/reducer";

export const store = configureStore({
  reducer: {
    tree: treeReducer,
    header: headerReducer,
    debug: debugReducer,
    Userdatasets: userDatasetReducer,
    datasets: datasetReducer,
  },
  preloadedState: {},
});

export type State = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

//Validation methods
//File Upload Validation
export const validateLocalUpload = (file: File) => {
  let allowedTypes = ["zip", "tar"];
  let type = file.name.split(".")[1];

  return allowedTypes.includes(type);
};

//BucketName Validation
//Bucket names can consist only of lowercase letters, numbers, dots (.), and hyphens (-).
export const validateBucketName = (value: string) => {
  if (value === "") {
    return true;
  }
  let bucketNameRegex = /^[a-z0-9.-]+$/;
  let result = bucketNameRegex.test(value);

  return result;
};

//Input Validation for the outer input where source is selected
//Need to make it applicable everywhere on site
export const validateDatasetName = (value: string) => {
  // Check if input is valid UTF-8
  try {
    // eslint-disable-next-line
    let utf8Text = decodeURIComponent(escape(value));
  } catch (err) {
    if (err) {
      return "Only UTF-8 supported";
    }
  }

  if (value === "") {
    return "";
  } else {
    let aplhaNumRegex = /^[a-zA-Z0-9\s]+$/;
    let result = aplhaNumRegex.test(value);
    if (result === false) {
      return "Please enter alphanumeric characters only";
    }

    if (value === "") {
      return "";
    } else {
      let validRegex =
        /^(?!.*(?:http|https))[a-zA-Z0-9/\s]+(?:\s+[a-zA-Z0-9\s]+)*$/;
      let result = validRegex.test(value);
      if (result === false) {
        return "Please enter a name without containing http/https";
      }
    }
  }
  return "";
};

import DropDown from "views/components/DropDown";

import BigImg from "assets/pngImages/BigImg.png";

import styles from "./style.module.scss";

function SelectSampleData(props: any) {
  return (
    <div className={props.show ? "d-block" : "d-none"}>
      <div className={styles.list}>
        <div className={styles.sourceName}>
          {props.selectedSourceName}&nbsp;
        </div>
        <div className={styles.iconImage}>
          <img src={BigImg} alt="" />
        </div>
      </div>
      <DropDown {...props} />
    </div>
  );
}
export default SelectSampleData;

export type ItemCenter = {
  x: number;
  y: number;
};

export type User = {
  origin: string;
  token: string;
};

export enum TabName {
  Explorer = "Datasets",
  Debug = "Debug",
}

export enum TabBasePath {
  //Explorer = "/datasets", - we still need to make the datasetsets tab unclickable
  Explorer = "/clusters",
  Debug = "/debug",
}

export type Tab = {
  name: TabName;
  path: string;
};

export enum Status {
  Init = "Init",
  Loading = "Loading",
  Loaded = "Loaded",
}

export type TreeNode = {
  components: Array<TreeNode>;
  name?: string;
  component_id: string;
  num_images: number;
  preview?: string;
  previews?: Array<string>;
  src?: string;
  type: string;
};

export type Tree = {
  status: Status;
  root?: TreeNode;
  breadcrumbs: Array<string>;
};

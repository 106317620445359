import RangeSlider from "react-bootstrap-range-slider";

import "./Slider.css";

const VlSlider = ({
  value,
  onValueChange,
  label,
}: {
  value: number;
  onValueChange: (n: number) => void;
  label: string;
}) => {
  return (
    <div className="vl-slider-wrapper">
      <div className="vl-slider">
        <RangeSlider
          value={value}
          onChange={(changeEvent) => {
            // parse string to number
            const value = parseInt(changeEvent?.target?.value);
            onValueChange(value);
          }}
        />
        <label className="vl-slider-label">
          {label}: {value}
        </label>
      </div>
    </div>
  );
};

export default VlSlider;

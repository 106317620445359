import { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import ContentLoad from "views/uikit/ContentLoad";
import { fetchDatasets } from "redux/Datasets/actions";
import { State } from "redux/store";
import {
  getDatasets,
  getContentLoading,
  getSampleData,
} from "redux/Datasets/selectors";

import * as Constants from "helpers/constants";
import {
  useWindowResize,
  calculatePlaceHolderCount,
  kFormatter,
} from "helpers/utils";

import Layout from "views/layout";
import Header from "views/layout/Header";
import StatsItem from "views/components/StatsItem";
import CreateDatasetModal from "views/components/CreateDatasetModal";

import { BiChevronDown, BiDotsVerticalRounded } from "react-icons/bi";
import cardImage from "assets/pngImages/Rectangle-new.png";

import styles from "./style.module.scss";

const DatasetInventory = (props: any) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const { height } = useWindowResize();
  const cardContainer = useRef<HTMLDivElement>(null);
  const cardElement = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const count: any = calculatePlaceHolderCount(
    height,
    cardContainer?.current,
    cardElement?.current,
    props.datasets?.length
  );

  useEffect(() => {
    dispatch(fetchDatasets() as any);
  }, [dispatch]);

  const createFirstDataset = (isReload: boolean = false) => {
    if (isReload) {
      dispatch(fetchDatasets() as any);
    }
  };

  //can be turned to an imported utility function
  var tooltipSpan: any = document.getElementById("tooltip-span");

  window.onmousemove = function (e) {
    var x = e.clientX,
      y = e.clientY;
    if (!!tooltipSpan) {
      tooltipSpan.style.top = y + 20 + "px";
      tooltipSpan.style.left = x + 20 + "px";
    }
  };

  return (
    <Layout>
      <Header {...props} />
      <div className={styles.datasetContainer}>
        <div className={styles.datasetCountDark}>
          <div>{`${props?.datasets?.length} Dataset${
            props?.datasets?.length > 1 ? "s" : ""
          }`}</div>
          <div>
            Display quality score: All
            <BiChevronDown size="1.5em" />
          </div>
        </div>
        <div
          ref={cardContainer}
          className={
            props.isContentLoading
              ? styles.cardSectionWithoutScroll
              : styles.CardSection
          }
        >
          {props?.datasets?.map((value: any, i: any) => (
            <div ref={cardElement} key={i} className={styles.datacardWrapper}>
              <div key={i} className={styles.dataCard}>
                <div className={styles.cardHead}>
                  <div className={styles.profile}>
                    <div title={value.display_name} className={styles.name}>
                      {value.display_name}
                    </div>
                    <div className={styles.imageCount}>
                      <span>{kFormatter(value.n_images)} Images</span>
                    </div>
                    <div className={styles.badge}>
                      <span className={styles.badgeValue}>
                        {value.source_type}
                      </span>
                    </div>
                  </div>
                  <div className={styles.qualityScoreContainer}>
                    <div
                      className={styles.qualityScoreBox}
                      style={{
                        backgroundColor: Constants.handleQualityScoreColor(
                          value.score
                        ),
                      }}
                    >
                      <span className={styles.qualityNumber}>
                        {value.score}
                      </span>
                    </div>
                    <span className={styles.qualityScoreText}>
                      QUALITY SCORE
                    </span>
                  </div>
                </div>
                <div className={styles.cardBody}>
                  <div className={styles.cardScores}>
                    <div className={styles.cardScoreContainer}>
                      {value.stats.map((stat: any, i: any) => (
                        <StatsItem
                          key={i}
                          color={Constants.StatsColors[i]}
                          severity={Constants.StatsType[i]}
                          count={stat.count}
                        />
                      ))}
                    </div>
                    <div className={styles.dotIcon}>
                      <BiDotsVerticalRounded size="2em" />
                    </div>
                  </div>
                  {isImageLoading && <ContentLoad />}
                  <div
                    className={
                      isImageLoading
                        ? styles.cardImageLoading
                        : styles.cardImage
                    }
                  >
                    <img
                      src={cardImage}
                      alt=""
                      onLoad={() => {
                        setIsImageLoading(false);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div
            className={props.isContentLoading ? "d-none" : styles.addDataset}
            onClick={() => props.setShowModal(true)}
          >
            + New Dataset
            <span className={styles.newDatasetTooltip} id="tooltip-span">
              Create a new dataset
            </span>
          </div>

          {count > 0 &&
            [...Array(count)].map((_, index) => (
              <div key={index} className={styles.cardPlaceHolder}>
                {props.isContentLoading && <ContentLoad />}
              </div>
            ))}
        </div>

        <CreateDatasetModal
          {...props}
          createFirstDataset={createFirstDataset}
        />
      </div>
    </Layout>
  );
};

function mapStatesToProps(state: State) {
  return {
    datasets: getDatasets(state),
    sampleData: getSampleData(state),
    isContentLoading: getContentLoading(state),
  };
}

export default connect(mapStatesToProps)(DatasetInventory);

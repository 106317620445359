import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";

import { userLogout } from "redux/authReducer";

import { User } from "types";

export const axiosConfig = (user: User) => {
  const headers: { [key: string]: string } = {
    Authorization: "Bearer " + user.token,
  };

  if (user.origin) {
    headers["x-user-origin"] = user.origin;
  }

  headers["Content-Type"] = "multipart/form-data";

  return { headers };
};

export const composeApiURL = (hostUrl: string, path: string) => {
  return hostUrl + "/api/v1" + path;
};

export const axiosGet = async (url: string, user: User): Promise<any> => {
  // call axios get with the given url and user and redirect to login upon 401 or 403
  try {
    return await axios.get(url, axiosConfig(user));
  } catch (error: any) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      userLogout();
    } else {
      throw error;
    }
  }
};

export const kFormatter = (num: any, digits: any = 1) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const formatBytes = (bytes: any, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const showNotification = (type: String, value: String) => {
  switch (type) {
    case "error":
      toast.error(value);
      break;
    case "info":
      toast.info(value);
      break;
    case "success":
      toast.success(value);
      break;
    case "warning":
      toast.warning(value);
      break;
  }
};

export const debounceCreator = (func: any, delay: number) => {
  let timeoutID: ReturnType<typeof setTimeout>;
  return (...args: any) => {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

let prevToken = "";
export const fetchGithubAvatar = async (token: string) => {
  if (token === prevToken) {
    return "";
  }
  try {
    let response = await axios.get("https://api.github.com/user", {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    const picture = await response.data?.avatar_url;
    prevToken = token;

    return picture;
  } catch (e) {
    console.log("error fetching the avatar", e);
  }
};

export const calculatePlaceHolderCount = (
  height: number,
  cardContainer: any,
  cardElement: any,
  datasetsLength: number
) => {
  let wrapperWidth = 0;
  let elementWidth = 0;
  let elementHeight = 0;

  if (cardContainer) {
    wrapperWidth = cardContainer.offsetWidth;
  }

  if (cardElement) {
    elementWidth = cardElement.offsetWidth;
    elementHeight = cardElement.offsetHeight;
  }

  const cols = Math.floor((wrapperWidth - 60) / elementWidth);
  const rows = Math.round((height - 175) / (elementHeight + 30));

  const placeholdersCount: any = cols * rows - datasetsLength - 1;

  return isNaN(parseInt(placeholdersCount)) ? 32 : placeholdersCount;
};

export function useWindowResize() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const listener = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  return {
    width,
    height,
  };
}

import { useState } from "react";
import { connect, useDispatch } from "react-redux";

import { IoIosArrowUp as OpenSelection } from "react-icons/io";
import { IoIosArrowDown as CloseSelection } from "react-icons/io";

import { State } from "redux/store";
import { setSelectedSampleDataset } from "redux/Datasets/actions";
import {
  getSelectedSampleDataset,
  getSampleData,
} from "redux/Datasets/selectors";

import styles from "./style.module.scss";

const DropDown = (props: any) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (value: any) => {
    dispatch(setSelectedSampleDataset(value));

    setIsOpenDropdown(false);
  };

  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdownHeader}>
        {/* static value */}
        <label>Select a sample dataset</label>
      </div>
      <div className={styles.dataSelection}>
        <div
          className={styles.selection}
          onClick={() => setIsOpenDropdown(!isOpenDropdown)}
        >
          <div className={styles.optionContatainer}>
            <span>{props?.selectedSampleDataset?.display_name}</span>
            <span>{props?.selectedSampleDataset?.description}</span>
          </div>
          <span>{isOpenDropdown ? <OpenSelection /> : <CloseSelection />}</span>
        </div>
        {isOpenDropdown && (
          <div className={styles.selectorOptions}>
            {props.sampleData &&
              props.sampleData.map((value: any, i: number): any => {
                return (
                  <div
                    key={i}
                    className={styles.option}
                    onClick={() => handleChange(value)}
                  >
                    {value.display_name}
                    <span>{value.description}</span>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

function mapStatesToProps(state: State) {
  return {
    selectedSampleDataset: getSelectedSampleDataset(state),
    sampleData: getSampleData(state),
  };
}

export default connect(mapStatesToProps)(DropDown);
